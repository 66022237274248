import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  return (
    <FooterContainer>
      <FooterText>© {year} Berlinest - All Rights Reserved</FooterText>
      <Links>

      <Link to={'/datenschutz'}>Datenschutz</Link>
      <Link to={'/cookiericht'}>Cookie</Link>
      </Links>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components for Footer
const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding:auto 20px ; */
  background-color: #282c34;
  color: #bfc2c9;
  font-size: 0.9rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

`;

const FooterText = styled.p`
  color: #7f8c8d;
  margin-bottom:0 ;
`;

const Links = styled.div`

  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  gap: 20px;
  text-decoration: none;
  margin-bottom:10px ;
  
`;