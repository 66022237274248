import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import { usePasswordValidation } from '../hooks/usePasswordValidation';
import PasswordStrengthMeter from '../components/PasswordStrengthMeter';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


const ChangePasswordPage = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    const formWrapperRef = useRef(null);
    const { changePassword } = useContext(AuthContext);
    const { error } = usePasswordValidation(newPassword)


    useEffect(() => {
        // Animation on page load using GSAP
        gsap.fromTo(formWrapperRef.current, { opacity: 0, y: -50 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
    }, []);

    useEffect(() => {
        setErrMsg('')
    }, [newPassword, confirmPassword, oldPassword])



    // Handle password reset logic
    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrMsg("Passwords do not match.");
            return;
        }

        if (error) {
            setErrMsg(error);
            return;
        }

        try {
            const response = await changePassword({ oldPassword, newPassword })
            console.log(response)

            if (response.statusText == "OK") {
                localStorage.removeItem('auth');
                window.location.reload();
            } else {
                setErrMsg('Password reset failed');
            }
        } catch (error) {
            if (!error?.response) {
                setErrMsg('An error occurred while resetting the password.');
            } else if (error?.response?.status === 400) {
                setErrMsg('Invalid or expired token')
            } else {
                setErrMsg('Error updating password')
            }
        }
    };

    return (
        <AuthContainer>
            <Navbar />
            <FormWrapper ref={formWrapperRef}>
                <Title>Passwort ändern</Title>
                <p className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>

                <Input
                    type="password"
                    placeholder="Altes Passwort"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Neues Passwort"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Passwort bestätigen"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <PasswordStrengthMeter password={newPassword} />
                <Button onClick={handleResetPassword}>Passwort ändern</Button>
                <LinkButton onClick={() => navigate('/login')}>Zurück zum Login</LinkButton>

            </FormWrapper>
            <Footer />
        </AuthContainer>
    );
};

export default ChangePasswordPage;

// Styled components
const AuthContainer = styled.div`
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden ;
  background: linear-gradient(135deg, #141e30, #243b55);
  animation: gradientBackground 6s ease infinite;
`;

const FormWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: auto;
  margin-bottom: 75px;

  @media (max-width: 768px) {
    width: 70%;
    max-width: 250px;
    
  }

`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    
  }
`;

const Input = styled.input`
  width: calc(100% - 30px);
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: 0.3s;
  &:focus {
    border-color: #6e8efb;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  margin-top: 1rem;
  background: #6e8efb;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #5a76d4;
    transform: scale(1.05);
  }
`;

const LinkButton = styled.p`
  margin-top: 1rem;
  color: #6e8efb;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;


