import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import useStore from '../store/useStore';

const Size = ({ style }) => {

    const { setSizeRangeFrom, setSizeRangeTo, sizeRangeFrom, sizeRangeTo } = useStore();
    const [menu, setMenu] = useState(style == 'case_1' ? 'none' : 'block')

    const sizeToRef = useRef();

    const sizeFromOption = [
        { key: 0, value: 'Fläche ab' },
        { key: 40, value: 'ab 40m²' },
        { key: 50, value: 'ab 50m²' },
        { key: 70, value: 'ab 70m²' },
        { key: 80, value: 'ab 80m²' },
        { key: 100, value: 'ab 100m²' }
    ];

    const sizeToOption = [
        { key: 0, value: 'Fläche bis' },
        { key: 40, value: 'bis 40m²' },
        { key: 50, value: 'bis 50m²' },
        { key: 70, value: 'bis 70m²' },
        { key: 80, value: 'bis 80m²' },
        { key: 90, value: 'bis 90m²' },
        { key: 101, value: 'mehr als 100m²' }
    ];



    const handleChange = (e) => {
        if (e.target.name === 'ab') {
            setSizeRangeFrom(e.target.value)


        } else if (e.target.name === 'to') {
            setSizeRangeTo(e.target.value)

        }

    }

    useEffect(() => {
        if (parseInt(sizeRangeTo) < parseInt(sizeRangeFrom)) {
            setSizeRangeTo(0)
            sizeToRef.current.value = 0

        }
    }, [sizeRangeFrom, sizeRangeTo])




    return (
        <Container>
            {/* <DropdownLabel>Select Room</DropdownLabel> */}
            <Select onChange={handleChange} defaultValue={sizeRangeFrom} name='ab' style={{ display: menu }}>
                {sizeFromOption.map((size, i) => (
                    <option key={i} value={size.key}>
                        {size.value}
                    </option>
                ))}
            </Select>
            <Select ref={sizeToRef} onChange={handleChange} defaultValue={sizeRangeTo} name='to'>
                {sizeToOption.map((size, i) => (
                    <option key={i} value={size.key}>
                        {size.value}
                    </option>
                ))}
            </Select>
        </Container>
    )
}

export default Size

const Container = styled.div`
  width: 150px;
  /* background-color: #f4f4f4;
  border: 1px solid #ccc; */
  border-radius: 5px;
  /* padding: 10px; */
  padding-top: 0;
  /* height: fit-content; */
  margin: 0;
`;

const DropdownLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 8px;
`;

const Select = styled.select`
  width: 100%;
  padding:10px;
  font-size: 14px;
  /* margin: 5px auto; */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #333;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;