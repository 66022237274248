import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import PriceRange from './PriceRange';
import SortWohnungen from './SortWohnungen';
import CompanyDropdown from './CompanyDropdown';
import Rooms from './Rooms';
import Wbs from './Wbs';
import useStore from '../store/useStore';
import Size from './Size';

const Company = ({ count, wbs, setWbs, sortKey, sortOrder, handleSortChange, setSortKey, setSortOrder }) => {
    const [allUrl, setAllUrl] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [update, setUpdate] = useState(false);
    const [priceFrom, setPriceFrom] = useState('');
    const [priceTo, setPriceTo] = useState('');
    const [activeCompanies, setActiveCompanies] = useState([]);
    const { setRefresh, setRangeChange, rangeChange, priceRangeFrom, priceRangeTo, setPriceRangeFrom, setPriceRangeTo } = useStore();

    // const [rangeChange, setRangeChange] = useState(false);
    // const [range, setRange] = useState([0, 100]);
    const axiosPrivate = useAxiosPrivate();
    const containerRef = useRef(null);

    const getCompany = async () => {
        try {
            const { data } = await axiosPrivate.get(`api/company/getcompanies`);
            const urlConfig = setAllUrl(data.urlConfigs);
            // setWbs(data.wbs)
            // setRange(data.range)


        } catch (error) {
            console.log('Error:', error);
        }
    };


    useEffect(() => {
        gsap.from(containerRef.current, { opacity: 0, y: 20, duration: 1.5, ease: 'power3.out' });
    }, []);

    useEffect(() => {
        getCompany();
    }, [])



    const handleChange = (e) => {
        const companyId = e.target.id;
        const active = e.target.checked;

        setUpdates((prev) => {
            const itemIndex = prev.findIndex((item) => item.id === companyId);
            if (itemIndex !== -1) {
                const updatedItems = [...prev];
                updatedItems[itemIndex].active = active;
                return updatedItems;
            } else {
                return [...prev, { companyId, active }];
            }
        });
    };

    const handleUpdate = async () => {
        try {
            if (updates) {
                await axiosPrivate.post('/api/company/setactive', { companyStatuses: updates }).then(() => {
                    setUpdates([]);
                });
            }

            if (rangeChange) {
                await setUserPreferences()
            }
            setRefresh(true);
        } catch (error) {
            console.log(error)
        }
    };

    // const handleChangeRange = (e) => {
    //     setRange(e.target.value);
    //     setRangeChange(true);
    // };





    // const userChangeWbs = async (e) => {
    //     setWbs(e.target.value);
    //     try {
    //         await axiosPrivate.post('api/wohnung/setwbs', { wbs: e.target.value });
    //         if (rangeChange) return;
    //         setRefresh(true)
    //     } catch (error) {
    //         console.log('Error:', error);
    //     }
    // };

    const setUserPreferences = async () => {
        try {
            const data = await axiosPrivate.post(`api/wohnung/setrange`, { range: priceRangeTo });
            if (data.status == 200) {
                setRangeChange(false)
            }
        } catch (error) {

        }
    }


    return (
        <Container ref={containerRef}>
            {allUrl.map((all, index) => (
                <CheckContainer key={index + all.id}>
                    <Label htmlFor={all.id}>{all.company}</Label>
                    <InputBox type='checkbox' name={all.company} defaultChecked={all.active} onChange={handleChange} id={all.id} />
                </CheckContainer>
            ))}

            {/* <CompanyDropdown update={update} setUpdate={setUpdate} activeCompanies={activeCompanies} setActiveCompanies={setActiveCompanies} /> */}

            <RangeContainer>
                <BoxRange>
                    {/* <PriceTitle>Gesamte Miete</PriceTitle> */}
                    {/* <Input type="range" onChange={handleChangeRange} value={range} min={0} max={5000} title='test' step={50} /> */}
                    {/* <Span>{range} € </Span> */}
                </BoxRange>
            </RangeContainer>
            <View>


                <Item>
                    <FilterTitle>WBS</FilterTitle>
                    <Wbs />
                </Item>
                <Item>
                    <FilterTitle>Zimmer</FilterTitle>
                    <Rooms />
                </Item>

                <Item>
                    <FilterTitle>Preis bis</FilterTitle>
                    <PriceRange style={'case_1'} />
                </Item>

                <Item>
                    <FilterTitle>Fläche</FilterTitle>
                    <Size style={'case_1'} />
                </Item>
            </View>
            <View>

            </View>
            <Box>

            </Box>
            <WrapContainer>
                <Button onClick={handleUpdate}>Suche anpassen <Count><p>{count}</p></Count> </Button>
                <SortWohnungen sortKey={sortKey} handleSortChange={handleSortChange} sortOrder={sortOrder} setSortKey={setSortKey} setSortOrder={setSortOrder} />
            </WrapContainer>

        </Container>
    );
};

export default Company;

const Container = styled.div`
    max-width: 700px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    /* background-color: #282c34; */
    color: #bfc2c9;
    /* border: 1px solid #ffffff11; */
    width: 100vw;
    margin: 20px auto;
    /* background: rgba(32, 30, 30, 0.14); */
    /* border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0.5px);
    -webkit-backdrop-filter: blur(0.5px); */


    background-color: #00000034;
    border-radius: 16px;
    box-shadow: 0 4px 30px -5px rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(0.5px);
    border: 1px solid rgba(32, 30, 30, 0.17);
    
    @media (max-width: 900px) {
    max-width: 90%;
  }
    @media (max-width: 760px) { width: initial; }
    @media (max-width: 600px) { padding: 10px; max-width: 458px; }
    @media (max-width: 425px) { 
        max-width: 95%;
        
     }
`;

const CheckContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: white;
    @media (max-width: 425px) {
         /* width: 120px; */
        /* padding: 5px 10px;  */

         }
`;

const InputBox = styled.input`
    cursor: pointer;
    color:blue;
    accent-color: #1c72d4;
    
   
`;

const Label = styled.label`
    text-transform: capitalize;
    user-select: none;
    text-align: left;
`;

const Button = styled.button`
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    border: 1px solid white;
    color: white;
    margin-left: 10px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 10px 20px;
    &:hover { 
        background-color: #f5f0f0;
        /* border-color:  #e74c3c; */
        color: black;
        & > :nth-child(1){
        display: block;
        p{
            color: #646464;
        }
    }
    }
   

   
`;

const RangeContainer = styled.div``;

const BoxRange = styled.div`
    display: flex;
    align-items: center;
    padding:  10px;
    /* border-bottom: 1px solid white; */
    
`;

const Input = styled.input`
    background-color: transparent;
    color: #eeae23;
    border: none;
    font-size: 1.1rem;
    width: 50px;
    padding-left: 10px;
    background-color: #0e0c0c63;
    border-bottom: 1px solid white;
    &:focus { outline: none; }
`;

const PriceTitle = styled.h4`
    color: white;
    user-select: none;
`;

const Span = styled.span`
    color: white;
    font-size: 1.3rem;
`;

const Box = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    color: white;
    gap: 10px;
`;

const Select = styled.select`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    &:focus { outline: none; }
`;

const Option = styled.option`
    background: gray;
`;

const Count = styled.div`
    padding-left: 5px;
    > p {
       text-align: center;
       font-size: 1.3rem;
       color: #ffffff11;
       margin: 0;
    }
`;


const View = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    @media (max-width: 825px) { gap: 20px;
    justify-content:center }

 

`
const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-left: 10px;
   
 
    
`

const FilterTitle = styled.h5`
    /* text-align: right; */
    margin: 0;
    padding:0;
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-weight: 500;
`

const WrapContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 625px) { width: 100%;
    justify-content:center }
  
`;