import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
    return (
        <Contanier>
            <Title>404 - Seite nicht gefunden</Title>
            <Text>Entschuldigung, die von Ihnen gesuchte Seite existiert nicht.</Text>
            <Link to="/">Zurück zur Startseite</Link>
        </Contanier>
    );
};

export default NotFound;

const Contanier = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    min-height: 60vh;
`

const Title = styled.h1``

const Text = styled.p``
