import useGetLocalStorage from './useGetLocalStorage';
import useSetLocalStorage from './useSetLocalStorage';

const useLocalStorage = (key) => {
  const getLocalStorage = useGetLocalStorage(key);
  const setLocalStorage = useSetLocalStorage(key);

  return {
    getLocalStorage,
    setLocalStorage
  };
};

export default useLocalStorage;
