import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <Button
            onClick={scrollToTop}
            $visible={visible}
        >
            ↑
        </Button>
    );
};

export default ScrollToTop;

// Styled-components for the button
const Button = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border: none;
    background-color: #007bff4b;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: ${({ $visible }) => ($visible ? 'block' : 'none')};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.5 ease;
    z-index: 50;

    &:hover {
        background-color: #0056b3;
    }
`;
