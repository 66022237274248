import React from "react";
import styled from "styled-components";

const Preistabelle = () => {
    const plaene = [
        {
            titel: "Ein Monat",
            preis: "€9,99",
            features: ["Zugriff auf Basisfunktionen", "Keine Vertragsbindung", "E-Mail-Support"],
            buttonText: "Jetzt starten",
        },
        {
            titel: "Drei Monate",
            preis: "€19,99",
            features: ["Alle Funktionen freigeschaltet", "Sparen Sie 15%", "Premium-Support"],
            buttonText: "Beliebtester Plan",
        },
        {
            titel: "Ein Jahr",
            preis: "€59,99",
            features: ["Alle Premiumfunktionen", "Sparen Sie 25%", "Persönlicher Support"],
            buttonText: "Maximale Einsparung",
        },
    ];

    return (
        <Container>
            <Ueberschrift>Wählen Sie Ihren Plan</Ueberschrift>
            <KartenGruppe>
                {plaene.map((plan, index) => (
                    <Karte key={index} $hervorheben={plan.titel === "Drei Monate"}>
                        <KartenKopf>
                            <KartenTitel>{plan.titel}</KartenTitel>
                            <KartenPreis>{plan.preis}</KartenPreis>
                        </KartenKopf>
                        <KartenFeatures>
                            {plan.features.map((feature, i) => (
                                <Feature key={i}>{feature}</Feature>
                            ))}
                        </KartenFeatures>
                        <Button>{plan.buttonText}</Button>
                    </Karte>
                ))}
            </KartenGruppe>
        </Container>
    );
};

export default Preistabelle;

// Styled Components

const Container = styled.div`
  /* padding: 3rem; */
  /* background: #f9f9f9; */
  text-align: center;
`;

const Ueberschrift = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
`;

const KartenGruppe = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

const Karte = styled.div`
  background: ${(props) => (props.$hervorheben ? "#007bff" : "#fff")};
  color: ${(props) => (props.$hervorheben ? "#fff" : "#333")};
  border: ${(props) => (props.$hervorheben ? "none" : "1px solid #ddd")};
  border-radius: 8px;
  /* padding: 2rem; */
  width: 210px;
  box-shadow: ${(props) => (props.$hervorheben ? "0px 4px 15px rgba(0, 0, 0, 0.2)" : "0px 4px 8px rgba(0, 0, 0, 0.1)")};
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${(props) => (props.$hervorheben ? "0px 6px 20px rgba(0, 0, 0, 0.3)" : "0px 6px 15px rgba(0, 0, 0, 0.2)")};
  }
`;

const KartenKopf = styled.div`
  margin-bottom: 1.5rem;
`;

const KartenTitel = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const KartenPreis = styled.p`
  font-size: 2rem;
  font-weight: bold;
`;

const KartenFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
`;

const Feature = styled.li`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

const Button = styled.button`
  background: ${(props) => (props.$hervorheben ? "#fff" : "#007bff")};
  color: ${(props) => (props.$hervorheben ? "#007bff" : "#fff")};
  border: ${(props) => (props.$hervorheben ? "2px solid #fff" : "none")};
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    background: ${(props) => (props.$hervorheben ? "#007bff" : "#0056b3")};
    color: ${(props) => (props.$hervorheben ? "#fff" : "#fff")};
  }
`;
