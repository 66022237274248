import PropTypes from "prop-types";
import styled from "styled-components";
import { Check, X } from "lucide-react";
import { usePasswordValidation } from "../hooks/usePasswordValidation"; // Import the hook

// Styled components (same as before)
const CriteriaContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const CriteriaItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: ${({ $met }) => ($met ? "#54a2fa" : "#9CA3AF")};
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  color: ${({ $met }) => ($met ? "#54a2fa" : "#6B7280")};
`;

const StrengthMeterContainer = styled.div`
  margin-top: 0.5rem;
`;

const StrengthHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #9ca3af;
`;

const StrengthBarContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const StrengthBar = styled.div`
  height: 0.25rem;
  width: 25%;
  border-radius: 9999px;
  background-color: ${({ $active, $strength }) =>
        $active
            ? $strength === 1
                ? "#F87171"
                : $strength === 2
                    ? "#FBBF24"
                    : $strength === 3
                        ? "#FACC15"
                        : "#54a2fa"
            : "#4B5563"};
  transition: background-color 0.3s ease;
`;

const PasswordCriteria = ({ criteria }) => (
    <CriteriaContainer>
        {criteria.map((item) => (
            <CriteriaItem key={item.label} $met={item.met}>
                <IconWrapper $met={item.met}>
                    {item.met ? <Check size={16} /> : <X size={16} />}
                </IconWrapper>
                <span>{item.label}</span>
            </CriteriaItem>
        ))}
    </CriteriaContainer>
);

PasswordCriteria.propTypes = {
    criteria: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            met: PropTypes.bool.isRequired,
        })
    ).isRequired,
};

const PasswordStrengthMeter = ({ password }) => {
    const { criteria, strength, error } = usePasswordValidation(password);

    const getStrengthText = (strength) => {
        if (strength === 0) return ""
        if (strength === 1) return "Sehr schwach";
        if (strength === 2) return "Schwach";
        if (strength === 3) return "Mittelmäßig";
        if (strength === 4) return "Gut";
        return "Stark";
    };

    return (
        <StrengthMeterContainer>
            <StrengthHeader>
                <span>Password strength</span>
                <span>{getStrengthText(strength)}</span>
            </StrengthHeader>

            <StrengthBarContainer>
                {[...Array(5)].map((_, index) => (
                    <StrengthBar
                        key={index}
                        $active={index < strength}
                        $strength={strength}
                    />
                ))}
            </StrengthBarContainer>

            {error && <div style={{ color: "#F87171" }}>{error}</div>}

            <PasswordCriteria criteria={criteria} />
        </StrengthMeterContainer>
    );
};

PasswordStrengthMeter.propTypes = {
    password: PropTypes.string.isRequired,
};

export default PasswordStrengthMeter;
