import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import AuthContext from '../../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from '../../hooks/useLocalStorge';
import PasswordStrengthMeter from '../PasswordStrengthMeter';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import useAuthStore from '../../store/authStore';


const AuthForms = () => {
    const [formType, setFormType] = useState('login');
    const [credential, setCredential] = useState({ email: "", password: "" });
    const [verifyForm, setVerifyForm] = useState(false);
    const [verify, setVerify] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const { login, register, sendVerify, resetPassword } = useContext(AuthContext);
    const [errMsg, setErrMsg] = useState('');
    const errRef = useRef();
    const { setAuth } = useAuth();
    const { setLocalStorage } = useLocalStorage("auth")
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const { error } = usePasswordValidation(credential.password)
    const { isAuthenticated, setIsAuthenticated, setToken, deviceId, generateDeviceId, setIsBanned, setBanMessage } = useAuthStore()

    const formWrapperRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(formWrapperRef.current, { opacity: 0, y: -50 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
    }, [formType]);

    useEffect(() => {
        generateDeviceId()
    }, [generateDeviceId])

    // Email Regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const checkValidate = ({ email, password }) => {
        if (!emailRegex.test(email)) {
            setErrMsg('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
            errRef.current.focus();
            return true;
        }
        if (!password) {
            setErrMsg('Passwort ist erforderlich.');
            errRef.current.focus();
            return true;
        }
        setErrMsg('');
        return false;
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        if (checkValidate(credential)) return;
        try {
            const response = await login(credential, deviceId);

            const accessToken = response.data.accessToken;
            if (!accessToken) {
                setErrMsg("Login Failed");
                setCredential({ email: "", password: "" });
                navigate('/login');
            }
            setAuth({ accessToken });
            setLocalStorage({ tk: accessToken });
            setIsAuthenticated(true)
            setToken(accessToken)

            navigate(from, { replace: true });
        } catch (err) {
            console.log(err)
            if (!err?.response) {
                setErrMsg('Keine Serverantwort');
            } else if (err.response?.status === 400) {
                setErrMsg('Benutzername oder Passwort fehlt');
            } else if (err.response?.status === 401) {
                setErrMsg('Nicht autorisiert');
            } else if (err.response?.status === 403) {
                setErrMsg('Ungültige E-Mail oder Passwort');
            } else if (err.response?.status === 409) {
                // setVerifyForm(true)
                setVerify(true)
                setVerifyForm(true)
                setErrMsg('Konto nicht verifiziert. Bitte überprüfen Sie Ihre E-Mails zur Verifizierung.');
            } else if (err.response.status === 429) {
                setIsBanned(true)
                setBanMessage('Zu viele Anfragen. Bitte versuchen Sie es später noch einmal.')

            } else {
                setErrMsg('Anmeldung fehlgeschlagen');
            }
            errRef.current.focus();
        }
    }

    useEffect(() => {
        setErrMsg("");
        // setVerify(false);
    }, [credential, formType]);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (checkValidate(credential)) return;
        if (error) {
            setErrMsg(error)
            return
        }
        try {
            const response = await register(credential);
            // setCredential({ email: "", password: "" });
            // setFormType('login');
            setVerifyForm(true)
            setVerify(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Keine Serverantwort');
            } else if (err.response?.status === 400) {
                setErrMsg('Anmeldedaten fehlen');
            } else if (err.response?.status === 405) {
                setErrMsg('Diese E-Mail darf nicht verwendet werden');
            } else if (err.response?.status === 409) {
                setErrMsg('E-Mail wird bereits verwendet');
            } else {
                setErrMsg('Benutzerregistrierung fehlgeschlagen');
            }
            console.error("Benutzerregistrierung fehlgeschlagen:", err);
            errRef.current.focus();
        }
    }

    const sendNewVerify = async () => {
        if (!emailRegex.test(credential.email)) {
            setErrMsg('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
            errRef.current.focus();
            return true;
        }
        if (!verify) setVerify(true)
        try {
            const response = await sendVerify(credential.email);
            if (response.status === 200) {
                setErrMsg('')
                // setVerify(true);
                // setFormType('login');
            }
        } catch (error) {
            setErrMsg('Benutzerregistrierung fehlgeschlagen');
        }
    }


    const userResetPassword = async (e) => {
        e.preventDefault();
        if (!emailRegex.test(credential.email)) {
            setErrMsg('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
            errRef.current.focus();
            return true;
        }

        try {
            const reset = await resetPassword(credential.email);

            setCredential({ email: "", password: "" })
            setEmailSent(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Keine Serverantwort');
            } else if (err.response?.status === 404) {
                setErrMsg('Benutzername nicht gefunden');
            } else {
                setErrMsg('Anmeldung fehlgeschlagen');
            }
        }
    }

    // useEffect(() => {
    //     if (emailSent) {
    //         const timer = setTimeout(() => {
    //             setEmailSent(false)
    //             setFormType('login')
    //         }, 5000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [emailSent]);


    useEffect(() => {
        let timeDisable;
        let countdownInterval;

        if (verify || verifyForm) {
            const timeoutDuration = 10000;
            setIsRunning(true)
            setRemainingTime(timeoutDuration / 1000);

            countdownInterval = setInterval(() => {
                setRemainingTime((prev) => Math.max(prev - 1, 0));
            }, 1000)

            timeDisable = setTimeout(() => {
                setIsRunning(false)
            }, timeoutDuration)
        }

        return () => {
            clearTimeout(timeDisable);
            clearInterval(countdownInterval)
            setIsRunning(false);
            setVerify(false)
            setErrMsg('')

        }
    }, [verify, verifyForm])

    const renderForm = () => {
        switch (formType) {
            case 'login':
                return (
                    !verifyForm ?
                        <form>
                            <ErrorMessage ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</ErrorMessage >
                            <Title>Anmelden</Title>
                            <Input type="email" placeholder="Email" onChange={(e) => setCredential((prev) => ({ ...prev, email: e.target.value }))} />
                            <Input type="password" placeholder="Passwort" onChange={(e) => setCredential((prev) => ({ ...prev, password: e.target.value }))} />
                            <Button type="submit" onClick={handleLogin}>Anmelden</Button>
                            {/* {verify && <Button type="button" onClick={sendNewVerify}>Bestätigung erneut senden</Button>} */}
                            <LinkButton onClick={() => setFormType('register')}>Konto erstellen</LinkButton>
                            <LinkButton onClick={() => setFormType('reset')}>Passwort vergessen?</LinkButton>
                        </form>
                        :
                        <>
                            <ErrorMessage ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</ErrorMessage >
                            <SuccessMessage>
                                Ein Link zur Verifizierung Ihres Kontos wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen, um Ihr Konto zu verifizieren.
                            </SuccessMessage>
                            <Input type="email" disabled={isRunning} placeholder="Email" value={credential.email} onChange={(e) => setCredential((prev) => ({ ...prev, email: e.target.value }))} />
                            <Button type="button" disabled={isRunning} onClick={sendNewVerify}>{remainingTime ? `Warte ${remainingTime}` : "Verifizierung erneut senden"} </Button>
                            <LinkButton onClick={() => {
                                setVerifyForm(false)
                            }}>Zurück zur Anmeldung</LinkButton>

                        </>

                );
            case 'register':
                return (
                    !verifyForm ?
                        <>
                            <ErrorMessage ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</ErrorMessage >
                            <Title>Registrieren</Title>
                            <Input type="email" placeholder="Email" onChange={(e) => setCredential((prev) => ({ ...prev, email: e.target.value }))} />
                            <Input type="password" placeholder="Passwort" onChange={(e) => setCredential((prev) => ({ ...prev, password: e.target.value }))} />
                            <PasswordStrengthMeter password={credential.password} />
                            <Button onClick={handleRegister}>Registrieren</Button>
                            <LinkButton onClick={() => setFormType('login')}>Haben Sie bereits ein Konto?</LinkButton>
                        </>
                        :
                        <>
                            <ErrorMessage ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</ErrorMessage >
                            <SuccessMessage>
                                Ein Link zur Verifizierung Ihres Kontos wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen, um Ihr Konto zu verifizieren.
                            </SuccessMessage>
                            <Input type="email" disabled={isRunning} placeholder="Email" value={credential.email} onChange={(e) => setCredential((prev) => ({ ...prev, email: e.target.value }))} />
                            <Button type="button" disabled={isRunning} onClick={sendNewVerify}>{remainingTime ? `Warte ${remainingTime}` : "Verifizierung erneut senden"} </Button>
                            <LinkButton onClick={() => {
                                setVerifyForm(false)
                            }}>Zurück zur Anmeldung</LinkButton>

                        </>
                );
            case 'reset':
                return (
                    !emailSent ?
                        <form>
                            <ErrorMessage ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</ErrorMessage >
                            <Title>Passwort zurücksetzen</Title>
                            <Input type="email" placeholder="Email" onChange={(e) => setCredential((prev) => ({ ...prev, email: e.target.value }))} />
                            <Button onClick={userResetPassword}>Passwort zurücksetzen</Button>
                            <LinkButton onClick={() => setFormType('login')}>Zurück zur Anmeldung</LinkButton>
                        </form>
                        :
                        <>
                            <SuccessMessage>
                                Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.
                                Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen, um Ihr Passwort zurückzusetzen.
                            </SuccessMessage>
                            <LinkButton onClick={() => {
                                setFormType('login')
                                setEmailSent(false)
                            }}>Zurück zur Anmeldung</LinkButton>

                        </>
                );

            default:
                return null;
        }
    };

    return (
        <AuthContainer>
            <FormWrapper ref={formWrapperRef}>{renderForm()}</FormWrapper>
        </AuthContainer>
    );
};

export default AuthForms;

const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #0f1116, #243b55);

`;

const FormWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 16px;
  color: red;
  background-color: #f8d7da;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  /* visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)}; */
  transition: opacity 0.5s ease, visibility 0.5s ease;

  &.errmsg {
    font-weight: bold;
  }

  &.offscreen {
    visibility: hidden;
    opacity: 0;
  }
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: calc(100% - 30px);
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: 0.3s;
  &:focus {
    border-color: #6e8efb;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  margin-top: 1rem;
  background: #6e8efb;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #5a76d4;
    transform: scale(1.05);
  }
`;

const LinkButton = styled.p`
  margin-top: 1rem;
  color: #6e8efb;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SuccessMessage = styled.p`
  color: #333;
  font-size: 1.2rem;
  margin-top: 1rem;
`;
