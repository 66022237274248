// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NoFound';
import useAuth from './hooks/useAuth';
import UpdatePassword from './pages/UpdatePassword';
import AuthForms from './components/auth/AuthContainer';
import VerifyAccount from './pages/AccountVerify';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Navigate } from 'react-router-dom';
import useAuthStore from './store/authStore';
import useAxiosPrivate from './hooks/useAxiosPrivate';
import PrimeUser from './pages/PrimeUser';
import useStore from './store/useStore';
import QRCodeGenerator from './components/generateQrCode';
import ChangePasswordPage from './pages/ChangePasswordPage';
import Datenschutz from './pages/Datenschutz';
import CookieRichtlinie from './pages/CookieRichtline';


const AuthRoute = ({ children }) => {
  const { isAuthenticated, isLoading, isBanned } = useAuthStore();

  if (isAuthenticated) {
    return <Navigate to='/' replace />;
  }


  return children;
};

const ProtectedRouteUser = ({ children }) => {
  // const { isAuthenticated, isLoading } = useAuthStore();

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (!isAuthenticated) {
  //   return <Navigate to='/login' replace/>;
  // }



  return children;
};


function App() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate()
  const { checkUser, isAuthenticated, isLoading, error, setIsAuthenticated, isBanned, banMessage } = useAuthStore();
  const { initializeSort } = useStore()
  const isLoggedIn = !!JSON.parse(localStorage.getItem('auth'))?.tk;
  // let isLoggedIn = isAuthenticated



  // useEffect(() => {
  //     checkUser(axiosPrivate)
  // }, [checkUser, axiosPrivate]);


  useEffect(() => {
    initializeSort();
  }, [initializeSort]);

  // // useEffect(() => {

  // // }, [isLoggedIn, auth])
  if (isLoading) return <div style={{ width: "100%", height: "100vh", background: "rgba(0,0,0,0.4)", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "5rem", color: "white" }}>Loading....</div>

  if (isBanned) return <p style={{ color: "red" }}>{banMessage}</p>

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<AuthRoute isAuthenticated={isLoggedIn}><AuthForms /></AuthRoute>} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/verify" element={<VerifyAccount />} />
        <Route path="/Datenschutz" element={<Datenschutz />} />
        <Route path="/cookiericht" element={<CookieRichtlinie />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/generate" element={<QRCodeGenerator />} />
        <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
          <Route path="/" element={<ProtectedRouteUser><Dashboard /></ProtectedRouteUser>} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/settings" element={<PrimeUser />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
