import React, { useEffect, useState, useRef } from 'react';
import Apartment from './Apartment';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useSocket from '../hooks/useSocket';
import styled from 'styled-components';
import useStore from '../store/useStore';


const Apartments = ({ setCount }) => {
    const controllerRef = useRef();
    const controller = new AbortController();
    controllerRef.current = controller;
    const axiosPrivate = useAxiosPrivate();
    const [wohnungs, setWohnungs] = useState([]);
    // const [socket, setSocket] = useState(null);
    const { newApartment, socket } = useSocket('http://localhost:3001');
    const { refresh, setRefresh, sortOrder, sortKey } = useStore();


    const { wbs, rooms, priceRangeFrom, priceRangeTo, sizeRangeFrom, sizeRangeTo } = useStore()

    useEffect(() => {
        setRefresh(true)
    }, [setRefresh])

    const getAllApartments = async () => {
        try {
            const option = { wbs, rooms, priceRangeFrom, priceRangeTo, sizeFrom: sizeRangeFrom, sizeTo: sizeRangeTo }
            const { data } = await axiosPrivate.post('api/wohnung', { option }, {
                signal: controller.signal
            })
            // setWohnungs(data.data)
            setCount(data.count)
            const sortPreferences = JSON.parse(localStorage.getItem('sort')) || {};
            const key = sortPreferences.key || 'sqltime';
            const order = sortPreferences.order || 'desc';
            const sortedData = sortApartments(data.data, key, order);
            setWohnungs(sortedData);
            // setSortKey(key);
            // setSortOrder(order);

            setRefresh(false)
        } catch (error) {
            // console.log(error)
        }
    }



    useEffect(() => {
        if (socket) {
            socket.on('newApartment', (apartment) => {
                const generateId = Math.floor(Math.random() * Date.now());
                apartment.id = generateId; // Generate a unique ID for animation

                // Add the new apartment to the state
                if (apartment.price <= priceRangeTo && apartment.rooms >= rooms && apartment.livingSpace <= sizeRangeTo) {
                    setWohnungs((prev) => {
                        return [apartment, ...prev]; // Add new apartment at the top
                    });
                }

            });

            // Cleanup on unmount
            return () => {
                if (socket) {
                    socket.off('newApartment');
                }
            };
        }
    }, [socket]);


    useEffect(() => {
        if (refresh) {
            getAllApartments().then(() => setRefresh(false))
        }
    }, [refresh])


    useEffect(() => {
        if (wohnungs.length > 0) {
            // setWohnungs((prev) => sortApartments(prev));
            const sorted = sortApartments(wohnungs);
            setWohnungs(sorted)
        }
    }, [sortKey, sortOrder]);

    const sortApartments = (list, key = sortKey, order = sortOrder) => {
        const sorted = [...list].sort((a, b) => {
            if (key === "price" || key === "rooms") {
                return order === "asc"
                    ? parseFloat(a[key]) - parseFloat(b[key])
                    : parseFloat(b[key]) - parseFloat(a[key]);
            } else if (key === "sqltime") {
                return order === "asc"
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            }
            return 0;
        });
        return sorted;
    };



    const element = wohnungs.length && wohnungs.map((wohnung, index) => <div key={index} className={`apartment-${wohnung.id}`} ref={controllerRef}>
        <Apartment title={wohnung.title} address={wohnung.address} rooms={wohnung.rooms} livingSpace={wohnung.livingSpace} price={wohnung.price} url={wohnung.url} sqltime={wohnung.sqltime} index={index} company={wohnung.company} isNew={wohnung.isNew == undefined ? false : true} /></div>)
    return (<>
        {refresh ? <h1 style={{ color: 'white' }}>Loading...</h1> : wohnungs.length == 0 ? <h1 style={{ color: 'white' }}>No Wohnoungs Angebot...</h1> : element}
    </>)
}

export default Apartments




