import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const useSocket = (url) => {
    const [socket, setSocket] = useState(null);
    const [newApartment, setNewApartment] = useState(null);
    const [test, setTest] = useState(null);

    useEffect(() => {
        const newSocket = io(url, { transports: ['websocket'], withCredentials: true });
        setSocket(newSocket);

        newSocket.on('newApartment', (apartment) => {
            setNewApartment(apartment);
        });

        newSocket.on('test', (data) => {
            setTest(data);
        });

        return () => {
            newSocket.disconnect();
        };
    }, [url]);

    return { socket, newApartment, test };
};

export default useSocket;
