import axios from '../api/axios';
import useAuthStore from '../store/authStore';
import useLocalStorage from './useLocalStorge';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const useRefreshToken = () => {
    const { setLocalStorage } = useLocalStorage('auth')
    const { setIsAuthenticated, setToken } = useAuthStore()
    const refresh = async () => {
        if (!isRefreshing) {
            isRefreshing = true;
            try {
                const response = await axios.post('/auth/refresh-token', {}, { withCredentials: true, timeout: 30000 }); // Timeout increased
                processQueue(null, response.data.accessToken);
                setLocalStorage({ tk: response.data.accessToken })
                setToken(response.data.accessToken);
                return response.data.accessToken;
            } catch (error) {
                processQueue(error, null);
                throw error;
            } finally {
                isRefreshing = false;
            }
        }

        return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
        });
    };

    return refresh;
};

export default useRefreshToken;
