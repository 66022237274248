import { create } from 'zustand';

const getLocalStorage = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
};

const useStore = create((set) => ({
    // Initialize state from localStorage or use default value
    rooms: getLocalStorage('rooms', 0),
    wbs: getLocalStorage('wbs', false),
    priceRangeFrom: getLocalStorage('priceRangeFrom', 0),
    priceRangeTo: getLocalStorage('priceRangeTo', 1000),
    sizeRangeFrom: getLocalStorage('szf', 0),
    sizeRangeTo: getLocalStorage('szt', 0),
    sortKey: 'sqltime',
    sortOrder: 'desc',
    refresh: true,
    rangeChange: false,

    // Setter functions to update state and save to localStorage
    setRooms: (rooms) => {
        localStorage.setItem('rooms', JSON.stringify(rooms)); // Save to localStorage
        set({ rooms });
    },
    setWbs: (wbs) => {
        localStorage.setItem('wbs', JSON.stringify(wbs)); // Save to localStorage
        set({ wbs });
    },
    setPriceRangeFrom: (priceRangeFrom) => {
        localStorage.setItem('priceRangeFrom', JSON.stringify(priceRangeFrom)); // Save to localStorage
        set({ priceRangeFrom });
    },
    setPriceRangeTo: (priceRangeTo) => {
        localStorage.setItem('priceRangeTo', JSON.stringify(priceRangeTo)); // Save to localStorage
        set({ priceRangeTo });
    },
    setSizeRangeFrom: (sizeRangeFrom) => {
        localStorage.setItem('szf', JSON.stringify(sizeRangeFrom)); // Save to localStorage
        set({ sizeRangeFrom });
    },
    setSizeRangeTo: (sizeRangeTo) => {
        localStorage.setItem('szt', JSON.stringify(sizeRangeTo)); // Save to localStorage
        set({ sizeRangeTo });
    },
    setSort: (key, order) => {
        set({ sortKey: key, sortOrder: order });
        localStorage.setItem('sort', JSON.stringify({ key, order })); // Save to localStorage   
    },
    initializeSort: () => {
        const storedSort = localStorage.getItem('sort');
        try {
            const item = storedSort ? JSON.parse(storedSort) : localStorage.removeItem('sort');;
            // console.log({ item })
            if (!item?.key || !item?.order) {
                set({ sortKey: "sqltime", sortOrder: "desc" })
            } else {
                set({ sortKey: item.key, sortOrder: item.order })
            }
            // set({ sortKey: "sqltime", sortOrder: "desc" })
        } catch (error) {
            // console.error('Error parsing JSON from localStorage:', error);
            // Default to default sorting
            localStorage.removeItem('sort');
            set({ sortKey: 'sqltime', sortOrder: 'desc' });

        }

    },

    setRefresh: (value) => set({ refresh: value }),
    setRangeChange: (value) => set(({ rangeChange: value }))
    // Add any other global state or functions you may need
}));



export default useStore;
