import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useStore from '../store/useStore';
import styled from 'styled-components';
import PrimeNotifyComponent from '../components/PrimeNotifyComponent';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PayPalPayment from '../components/PayPalPayment';
import PricingTable from '../components/PricingTable';
import { ContainerIcon } from 'lucide-react';



const PrimeUser = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('loading');
    const axiosPrivate = useAxiosPrivate();

    // Fetch user data from the server
    const checkUser = async () => {
        try {
            setLoading(true);
            const response = await axiosPrivate.get('/api/user/prime');
            if (response.status === 200) {
                setIsAuthenticated(response.data.isAuthenticated);
                setType('prime');

            } else {
                setIsAuthenticated(false);
                setType('non-prime');
            }
            setLoading(false);
        } catch (error) {
            // console.log(error);
            setIsAuthenticated(false);
            setType('non-prime');
            setLoading(false);
        }
    }



    useEffect(() => {
        if (loading) setType('loading');
        const timer = setTimeout(() => {
            checkUser();
        }, 2000)
        return () => clearTimeout(timer);

        // checkUser()

    }, [])






    const renderContent = () => {
        switch (type) {
            case 'loading':
                return (
                    <div>
                        <h2>Checking User Status...</h2>
                    </div>
                );

            case 'prime':
                return (
                    <div>
                        {/* <h2>Prime User</h2>
                        <p>You are a prime user.</p> */}
                        <PrimeNotifyComponent />
                    </div>
                );
            case 'non-prime':
                return (
                    <Container>
                        <ContianerContent>
                            <h2>Abonnement: Wohnung Suchen in Berlin – 9,99 € für 1 Monat</h2>
                            <h5>Mit diesem Abonnement erhalten Sie regelmäßig E-Mail-Benachrichtigungen zu neuen Wohnungen, die kürzlich von derselben Firma hinzugefügt wurden. So bleiben Sie immer auf dem neuesten Stand und verpassen keine Angebote.</h5>
                            <p> Personalisierte Suchoptionen:</p>
                            <ul>
                                <li>Zimmeranzahl: Wählen Sie die gewünschte Anzahl der Zimmer.</li>
                                <li>Preis: Legen Sie einen Preisbereich für die Miete fest.</li>
                                <li>Wohnfläche: Definieren Sie die Mindest- und Höchstgröße Ihrer Wunschwohnung.</li>
                                <li>WBS (Wohnberechtigungsschein): Entscheiden Sie, ob Sie Wohnungen mit oder ohne WBS bevorzugen.</li>
                            </ul>
                            <PaymentBox>
                            {/* <PayPalPayment setType={setType} checkUser={checkUser} /> */}
                        </PaymentBox>
                        </ContianerContent>
                            <p>Passen Sie Ihre Wohnungssuche individuell an und erhalten Sie nur relevante Angebote, die Ihren Bedürfnissen entsprechen.</p>
                    </Container>
                );
            default:
                return (
                    <div>
                        <h2>Checking User Status...</h2>
                    </div>
                );
        }
    }

    return (
        <>
            <AuthContainer>
                <Navbar />
                <FormWrapper>
                    {renderContent()}
                </FormWrapper>
                <Footer />
            </AuthContainer>
        </>
    )
}


export default PrimeUser;


const AuthContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #0f1116, #122b50);
  padding-bottom: 2rem;
  /* margin: auto; */
  overflow: hidden;
  position: relative;





`;

const FormWrapper = styled.div`
  background-color: #00000034;
  border-radius: 16px;
    box-shadow: 0 4px 30px -5px rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(0.5px);
    border: 1px solid rgba(32, 30, 30, 0.17);
  color: #fff;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  border-radius: 12px;
  margin:  auto;
  text-align: center;

  @media  (max-width: 768px){
    max-width:calc(100% - 80px);
    margin-bottom: 100px;
  }
`;

const PaymentBox = styled.div`
  margin-top: 2rem;
  width: 50%;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  `

const ContianerContent = styled.div`
    width: 100%;
    text-align: left;
`