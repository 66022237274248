import React, { useState } from 'react';
import styled from 'styled-components';
import useStore from '../store/useStore';

const PriceRange = ({ style }) => {

  const { setPriceRangeFrom, setPriceRangeTo, priceRangeFrom, priceRangeTo, setRangeChange } = useStore();
  const [menu, setMenu] = useState(style == 'case_1' ? 'none' : 'block')


  const handlePriceFromChange = (e) => {
    setPriceRangeFrom(e.target.value);
  };

  const handlePriceToChange = (e) => {
    setPriceRangeTo(e.target.value);
    setRangeChange(true)
  };



  return (
    <Container>
      {/* <Title>Select Price Range</Title> */}
      <InputsWrapper>
        <Input
          type="number"
          min={0}
          placeholder="Price from"
          value={priceRangeFrom}
          onChange={handlePriceFromChange}
          style={{ display: menu }}
        />
        {/* <Separator>-</Separator> */}
        <Input
          type="number"
          max={10000}
          placeholder="Price to"
          value={priceRangeTo}
          onChange={handlePriceToChange}
        />
      </InputsWrapper>
      <PriceDisplay>
        {/* <span>Price range: {priceRangeFrom} to {priceRangeTo}</span> */}
      </PriceDisplay>
    </Container>
  );
};

export default PriceRange;

const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 150px;
  /* margin: 20px auto; */
  /* padding: 20px; */
  /* background-color: #f4f4f4; */
  border-radius: 10px;
`;

const Title = styled.h3`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  /* margin-bottom: 20px; */
`;

const Input = styled.input`
  width: calc(100% - 22px);
  padding: 10px;
  font-size: 14px;
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: #3264fe;
  }
`;

const Separator = styled.span`
  font-size: 18px;
  color: #333;
`;

const PriceDisplay = styled.div`
  font-size: 18px;
  color: #555;
  /* margin-top: 10px; */
`;
