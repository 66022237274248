import { create } from "zustand";

const useAuthStore = create((set) => ({
    user: null,
    isAuthenticated: false,
    error: null,
    isLoading: false,
    isCheckingAuth: true,
    message: null,
    token: null,
    deviceId: null,
    isBanned: false,
    banMessage: "",

    setIsAuthenticated: (value) => set({ isAuthenticated: value }),
    setIsBanned: (banned) => set({ isBanned: banned }),
    setBanMessage: (message) => set({ banMessage: message }),
    setToken: (value) => set({ token: value }),
    checkUser: async (axiosInstance) => {
        set({ isLoading: true, error: null });
        try {
            const response = await axiosInstance.get('/api/user/checkuser', { withCredentials: true });
        
            set({
                isLoading: false,
                error: null,
                isAuthenticated: true,
                // user: response.data.user,
            });
            return response.data.isAuth
        } catch (error) {
            set({
                isLoading: false,
                error: error.response?.data?.message || 'Failed to check user',
                isAuthenticated: false,
            });
        }
    },
    generateDeviceId: () => {
        const existingDeviceId = localStorage.getItem('d_id');
        if (existingDeviceId) {
            set({ deviceId: existingDeviceId });
        } else {
            const newDeviceId = Math.random().toString(36).substr(2, 16);
            localStorage.setItem('d_id', newDeviceId);
            set({ deviceId: newDeviceId });
        }
    },
    logout: async (axiosInstance, device_id) => {
        set({ isLoading: true, error: null });
        try {
            await axiosInstance.post('/auth/logout', { device_id: device_id }, { withCredentials: true });
            set({
                isLoading: false,
                error: null,
                isAuthenticated: false,
                user: null,
                token: null,
                deviceId: null,
            });
            document.cookie = "accessToken=; Max-Age=0; path=/"; // Clear cookies
            document.cookie = "refreshToken=; Max-Age=0; path=/";
            localStorage.removeItem('auth');
        } catch (error) {
            set({
                isLoading: false,
                error: error.response?.data?.message || 'Failed to logout',
                isAuthenticated: false,
            });
        }
    }
}));

export default useAuthStore;
