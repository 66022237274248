import React from 'react'
import styled from 'styled-components'
import useStore from '../store/useStore';
import useAxiosPrivate from '../hooks/useAxiosPrivate';



const Wbs = () => {
    const { wbs, setWbs,setRefresh } = useStore();
    const axiosPrivate = useAxiosPrivate();

    
    const userChangeWbs = async (e) => {
        setWbs(e.target.value);
        try {
            await axiosPrivate.post('api/wohnung/setwbs', { wbs: e.target.value });
            // if (rangeChange) return;
            setRefresh(true)
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <Container>
            {/* <Title>WBS</Title> */}
            <Select title="wbs" onChange={userChangeWbs} value={wbs}>
                <Option value="true">Erforderlich</Option>
                <Option value="false">Egal</Option>
            </Select>
        </Container>
    )
}

export default Wbs

const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 150px;
  /* margin: 20px auto; */
  /* padding: 20px; */
  /* background-color: #f4f4f4; */
`;

const Title = styled.h4`
    color: white;
    user-select: none;
`;

const Select = styled.select`
width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    &:focus { outline: none; }
`;

const Option = styled.option`
`;