import { createContext, useEffect, useState } from "react";
import axiosInter from "../api/axios";
import useLocalStorage from "../hooks/useLocalStorge";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(false)
    const { setLocalStorage } = useLocalStorage("auth")
    const axiosPrivate = useAxiosPrivate()
    useEffect(() => {

    }, [auth])

    const login = async (credential, deviceId) => {
        const { email, password } = credential;
        const response = await axiosInter.post('auth/login', { email, password, deviceId }, { withCredentials: true })
        return response
    }

    const register = async (credential) => {
        const newUser = await axiosInter.post("auth/register", credential)
        return newUser
    }

    const sendVerify = async (credential) => {
        const sendEmail = await axiosInter.post("auth/sendverify", { email: credential })
        return sendEmail;
    }

    const resetPassword = async (email) => {
        const response = await axiosInter.post("/auth/reset-password", { email })
        return response
    }

    const updatePassword = async ({ token, newPassword }) => {
        const response = await axiosInter.post("/auth/update-password", { token, newPassword })
        return response
    }

    const verifyAccount = async ({ token }) => {
        const response = await axiosInter.post('/auth/verify', { token })
        return response
    }

    const changePassword = async ({ oldPassword,newPassword }) => {
        const response = await axiosPrivate.post('/auth/change-password', { oldPassword, newPassword })
        return response;
    }


    const logout = async () => {
        const local = JSON.parse(localStorage.getItem('auth'))?.tk;
        if (local === undefined) return;
        await axiosInter.post('/auth/logout', {}, { withCredentials: true });
        setAuth({});
        setLocalStorage({})
    };
    return (
        <AuthContext.Provider value={{ auth, setAuth, loading, setLoading, logout, login, register, sendVerify, resetPassword, updatePassword, verifyAccount,changePassword }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;