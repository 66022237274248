import React from 'react';
import styled from 'styled-components';
import useStore from '../store/useStore';


const Rooms = () => {
  const {rooms,setRooms} = useStore();

  const roomsOption = [
    { key: 0, value: 'Zimmer' },
    { key: 1, value: 'ab 1 Zimmer' },
    { key: 2, value: 'ab 2 Zimmer' },
    { key: 3, value: 'ab 3 Zimmer' },
    { key: 4, value: 'ab 4 Zimmer' }
  ];

  const handleChange = (e) => {
    console.log('Selected Room Option:', e.target.value);
    const value = e.target.value
    setRooms(value)
  };



  return (
    <ContainerRoom>
      {/* <DropdownLabel>Select Room</DropdownLabel> */}
      <Select onChange={handleChange} defaultValue={rooms}>
        {roomsOption.map((room) => (
          <option key={room.key} value={room.key}>
            {room.value}
          </option>
        ))}
      </Select>
    </ContainerRoom>
  );
};

export default Rooms;

const ContainerRoom = styled.div`
  width: 150px;
  /* background-color: #f4f4f4; */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* padding-left:  10px; */
  padding-right:  10px;
  height: fit-content;
`;

const DropdownLabel = styled.h4`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 8px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #333;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;
