import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ToggleButton from "./ToggleButton";
import useToast from "../hooks/useToast";

const PrimeNotifyComponent = () => {
  const [isPrime, setIsPrime] = useState(false); // Prime User toggle
  const axiosPrivate = useAxiosPrivate();
  const [options, setOptions] = useState({});
  const [update, setUpdate] = useState(false);
  const [isToggle, setIsToggle] = useState(false)
  const [dayLeft, setDayLeft] = useState(0)
  const sizeToRef = useRef(null);
  const { warn } = useToast()

  const getUserVariable = async () => {
    try {
      const userVariable = await axiosPrivate.get('/api/user/getprime');
      setOptions(userVariable.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserVariable()
    NotifyStatus()
  }, [isPrime])

  const NotifyStatus = async () => {
    try {
      const response = await axiosPrivate.post('/api/user/notifystatus', { method: 'get' });
      console.log(response.data)
      setDayLeft(response.data.daysLeft)
      setIsToggle(response.data.notify)
    } catch (error) {
      console.log('Error:', error);
    }
  }




  const handleChange = (e) => {
    setUpdate(true)
    const { name, checked } = e.target;
    if (name === 'wbs') {
      const wbsValue = e.target.value === 'true';
      setOptions(prev => ({ ...prev, wbs: wbsValue }));
    }
    if (name === 'rooms') {
      const roomsValue = Number(e.target.value);
      setOptions(prev => ({ ...prev, rooms: isNaN(roomsValue) ? 0 : roomsValue }));
    }
    if (name === 'rangeFrom') {
      const rangeFromValue = Number(e.target.value);
      setOptions(prev => ({ ...prev, rangeFrom: isNaN(rangeFromValue) ? 0 : rangeFromValue }));
    }
    if (name === 'rangeTo') {
      const rangeToValue = Number(e.target.value);
      setOptions(prev => ({ ...prev, rangeTo: isNaN(rangeToValue) ? 0 : rangeToValue }));
    }
    if (name === 'sizeFrom') {
      const sizeFromValue = Number(e.target.value);
      setOptions(prev => ({ ...prev, sizeFrom: isNaN(sizeFromValue) ? 0 : sizeFromValue }));
    }
    if (name === 'sizeTo') {
      const sizeToValue = Number(e.target.value);
      setOptions(prev => ({ ...prev, sizeTo: isNaN(sizeToValue) ? 0 : sizeToValue }));
    }
    if (name === 'companies') {
      setOptions(prev => ({ ...prev, companies: checked ? [...prev.companies, e.target.value] : prev.companies.filter(c => c !== e.target.value) }));
    }
  }



  const userNotifyOption = async () => {

    try {
      const userNotify = await axiosPrivate.post('/api/user/setusernotify', options)
      setUpdate(false)
    } catch (error) {
      console.log(error)
    }
  }


  const handleChangeNotification = async (state) => {
    try {
      await axiosPrivate.post('/api/user/notifystatus', { notify: state, method: "set" });
      setIsToggle(state)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
  if (parseInt(options.sizeTo) < parseInt(options.sizeFrom)) {
    setOptions(prev => ({ ...prev, sizeTo: 0 }));
    sizeToRef.current.value = 0;
    if(options.sizeTo !== 0){
      warn('Größerer Grundfläche muss kleiner sein als kleiner Grundfläche');
    }

  }

  }, [options.sizeTo,options.sizeFrom])

  return (
    <Container >
      {/* Checkboxes */}
      <ToggleGroup>
        <Box >
          <ToggleButton handleChange={handleChangeNotification} isToggled={isToggle} />

          <Title $active={isToggle}>Aktive Benachrichtigung</Title>
        </Box>
        <Description>Wenn die Benachrichtigung aktiviert ist, erhalten Sie eine E-Mail für jede Wohnung, die kürzlich von derselben Firma hinzugefügt wurde.</Description>
      </ToggleGroup>
      <CheckboxGroup>
        {options.allCompanies && options.allCompanies.map((company, index) => (
          <Checkbox key={company + index}>
            <input type="checkbox" id={index} name="companies" defaultChecked={options.companies.includes(company)} onChange={handleChange} value={company} disabled={!isToggle} />
            <label htmlFor={index}>{company}</label>
          </Checkbox>))}


      </CheckboxGroup>

      {/* Dropdown Filters */}
      <FilterGroup>
        <Dropdown>
          <label>WBS</label>
          <select value={options.wbs} onChange={handleChange} name="wbs" disabled={!isToggle}>
            <option value={false}>Egal</option>
            <option value={true}>Erforderlich</option>
          </select>
        </Dropdown>
        <Dropdown>
          <label>Zimmer</label>
          <select value={options.rooms} onChange={handleChange} name="rooms" disabled={!isToggle}>
            <option value={0}>Egal</option>
            <option value={1} >ab 1 Zimmer</option>
            <option value={2}>ab 2 Zimmer</option>
            <option value={3}>ab 3 Zimmer</option>
            <option value={4}>ab 4 Zimmer</option>
          </select>
        </Dropdown>
        <InputField>
          <label>Preis ab</label>
          <input type="number" placeholder={options.rangeFrom} defaultValue={options.rangeFrom} onChange={handleChange} name="rangeFrom" min={0} max={10000} disabled={!isToggle} />
        </InputField>
        <InputField>
          <label>Preis bis</label>
          <input type="number" placeholder={options.rangeTo} defaultValue={options.rangeTo} onChange={handleChange} name="rangeTo" min={0} max={10000} disabled={!isToggle} />
        </InputField>
        <Dropdown>
          <label>Fläche ab</label>
          <select value={options.sizeFrom} onChange={handleChange} name="sizeFrom" disabled={!isToggle}>
            <option value={0}>Fläche ab</option>
            <option value={30}>ab 30 m²</option>
            <option value={40}>ab 40 m²</option>
            <option value={50}>ab 50 m²</option>
            <option value={70}>ab 70 m²</option>
            <option value={80}>ab 80 m²</option>
            <option value={100}>ab 100 m²</option>
          </select>
        </Dropdown>
        <Dropdown>
          <label>Fläche bis</label>
          <select value={options.sizeTo} onChange={handleChange} name="sizeTo" disabled={!isToggle} ref={sizeToRef}>
            <option value={0}>Fläche bis</option>
            <option value={30}>30 m²</option>
            <option value={40}>40 m²</option>
            <option value={50}>50 m²</option>
            <option value={70}>70 m²</option>
            <option value={80}>80 m²</option>
            <option value={100}>100 m²</option>
            <option value={101}>mehr als 100 m²</option>
          </select>
        </Dropdown>
      </FilterGroup>

      {/* Submit Button */}
      <ButtonGroup>
        <Button $active={isToggle} onClick={userNotifyOption} disabled={!isToggle} className={isToggle ? "isActive" : "notActive"}>Speicher</Button>
        {/* <DayLeftStyle $dayLeft={dayLeft}>{dayLeft}<p>Tage übrig</p></DayLeftStyle> */}
      </ButtonGroup>
    </Container>
  );
};

export default PrimeNotifyComponent;

// Styled Components

const Container = styled.div`
  /* background-color: #13161d; */
  padding: 2rem;
  border-radius: 12px;
  color: #fff;
  width: (100% - 2rem) / 2;
  max-width: 700px;
  margin: 0 auto;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1.5rem;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  input {
    cursor: pointer;
    accent-color:#0075ff
  }
  label {
    font-size: 1rem;
    cursor: pointer;
  }
`;

const FilterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  label {
    font-size: 0.9rem;
    text-align: left;
    padding-left: 8px;

  }
  select {
    padding: 0.5rem;
    border: none;
    border-radius: 8px;
    background-color: #1e212a;
    color: #fff;
    font-size: 1rem;
  }
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  label {
    font-size: 0.9rem;
    text-align: left;
    padding-left: 8px;
  }
  input {
    padding: 0.5rem;
    border: none;
    border-radius: 8px;
    background-color: #1e212a;
    color: #fff;
    font-size: 1rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  background-color: ${(props) => props.$active ? '#007bff' : 'gray'};
  color: #fff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: ${(props) => (props.$active ? 'pointer' : 'default')};
  transition: background-color 0.3s;

  

  &.isActive {
    &:hover{
      background-color: ${(props) => props.$active ? '#0056b3' : '#007bff'};
    }
  }

  
`;

const ToggleGroup = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: flex-start; */
  flex-direction: column;
  /* gap: 1rem; */
  `

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0;
  
  `

const Title = styled.h1`
  font-size: 1rem;
  color:${((props) => props.$active ? '#007bff' : '#343a40')};
`;

const Description = styled.p`
  color: #6c757d;
  font-size: 0.8rem;
  text-align: left;
  margin:0  ;
  margin-bottom: 0.5rem;
  `

const DayLeftStyle = styled.div`
    font-size: 0.8rem;
    color: ${(props) => props.$dayLeft > 5? '#ffffff' : '#dc3545'};
  p{
    display: inline-block;
    padding-left: 5px;
    color: #ffffff;
  }
`