import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import useAuthStore from "../store/authStore";

const decodeJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
        return null;
    }
};

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const { setIsAuthenticated, setToken,setBanMessage,setIsBanned } = useAuthStore()

  

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            async config => {
                const token = JSON.parse(localStorage.getItem('auth')).tk;
                const decodedToken = decodeJwt(token);
                if (decodedToken && Date.now() > decodedToken.exp * 1000) {
                    try {
                        const newAccessToken = await refresh();
                        setToken(newAccessToken);
                        setIsAuthenticated(true);
                        config.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    } catch (error) {
                        console.error("Failed to refresh token:", error);
                        setIsAuthenticated(false);
                        localStorage.removeItem('auth');
                        window.location.href = '/login'; // Redirect on failure
                        return Promise.reject(error);
                    }
                } else if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }

                return config;
            },
            error => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;

                  // Handle Rate Limiter 429 Errors
                  if (error?.response?.status === 429) {
                    setIsBanned(true);
                    setBanMessage(error.response.data.error || "You are temporarily banned.");
                    return Promise.reject(error);
                }


                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    try {
                        const newAccessToken = await refresh();
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        setToken(newAccessToken);
                        setIsAuthenticated(true);
                        return axiosPrivate(prevRequest);
                    } catch (refreshError) {
                        console.error("Refresh token failed:", refreshError);
                        setIsAuthenticated(false);
                        localStorage.removeItem('auth');
                        window.location.href = '/login'; // Redirect on failure
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh]);

    return axiosPrivate;
};

export default useAxiosPrivate;
