import { useMemo } from "react";

export const usePasswordValidation = (password) => {
  const criteria = useMemo(
    () => [
      { label: "Mindestens 6 Zeichen", met: password.length >= 6 },
      { label: "Enthält einen Großbuchstaben", met: /[A-Z]/.test(password) },
      { label: "Enthält einen Kleinbuchstaben", met: /[a-z]/.test(password) },
      { label: "Enthält eine Zahl", met: /\d/.test(password) },
      { label: "Enthält ein Sonderzeichen", met: /[^A-Za-z0-9]/.test(password) },
    ],
    [password]
  );

  const strength = useMemo(() => criteria.filter((c) => c.met).length, [criteria]);

  const error =
    strength < criteria.length && password
      ? "Das Passwort ist nicht stark genug. Bitte erfüllen Sie alle Kriterien."
      : null;

  return { criteria, strength, error };
};
