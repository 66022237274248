import React from 'react';

const CookieRichtlinie = () => {
  return (
    <div style={containerStyle}>
      <h1>Cookie-Richtlinie für Berlinest</h1>

      <section>
        <h2>1. Was sind Cookies?</h2>
        <p>
          Cookies sind kleine Textdateien, die auf deinem Gerät gespeichert werden, wenn du eine Webseite besuchst. Sie helfen uns, 
          deine Präferenzen zu speichern und die Nutzung unserer Webseite zu verbessern.
        </p>
      </section>

      <section>
        <h2>2. Welche Cookies verwenden wir?</h2>
        <p>Wir verwenden folgende Arten von Cookies:</p>
        <ul>
          <li><strong>Essentielle Cookies:</strong> Diese Cookies sind notwendig, um grundlegende Funktionen der Webseite zu ermöglichen, wie z.B. das Speichern deines Login-Status.</li>
          <li><strong>Funktionale Cookies:</strong> Diese Cookies ermöglichen es uns, deine Einstellungen und Präferenzen zu speichern, um das Nutzererlebnis zu verbessern.</li>
          <li><strong>Analytische Cookies:</strong> Diese Cookies helfen uns, die Nutzung der Webseite zu analysieren, um unsere Angebote zu optimieren.</li>
        </ul>
      </section>

      <section>
        <h2>3. Warum verwenden wir Cookies?</h2>
        <p>
          Wir verwenden Cookies, um die Funktionalität der Webseite zu verbessern, die Nutzererfahrung zu personalisieren und die 
          Nutzung zu analysieren, damit wir dir relevante Inhalte bieten können.
        </p>
      </section>

      <section>
        <h2>4. Wie kannst du Cookies kontrollieren?</h2>
        <p>
          Du hast die Möglichkeit, Cookies in den Einstellungen deines Browsers zu kontrollieren und zu verwalten. Du kannst Cookies 
          entweder ablehnen oder löschen, jedoch kann dies die Funktionalität einiger Teile der Webseite beeinträchtigen.
        </p>
      </section>

      <section>
        <h2>5. Weitere Informationen</h2>
        <p>
          Für weitere Informationen über Cookies und wie du sie verwalten kannst, besuche bitte die Hilfe-Seite deines Browsers oder 
          kontaktiere uns unter [Deine E-Mail-Adresse].
        </p>
      </section>

      <section>
        <h2>6. Änderungen dieser Cookie-Richtlinie</h2>
        <p>
          Wir behalten uns vor, diese Cookie-Richtlinie jederzeit zu ändern. Alle Änderungen werden auf dieser Seite veröffentlicht.
        </p>
      </section>
    </div>
  );
};

const containerStyle = {
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor:'#f4f4f4',
};

export default CookieRichtlinie;
