import { backIn } from 'framer-motion';
import React from 'react';

const Datenschutz = () => {
  return (
    <div style={containerStyle}>
      <h1>Datenschutzerklärung für Berlinest</h1>
      
      <section>
        <h2>1. Einleitung</h2>
        <p>
          Wir freuen uns, dass du die Webseite <strong>Berlinest</strong> besuchst. Der Schutz deiner persönlichen Daten ist uns sehr wichtig.
          In dieser Datenschutzerklärung erklären wir, wie wir deine personenbezogenen Daten erheben, verarbeiten und nutzen, wenn du
          unsere Webseite oder App nutzt.
        </p>
      </section>
      
      <section>
        <h2>2. Verantwortliche Stelle</h2>
        <p>
          Verantwortlich für die Verarbeitung deiner Daten ist:
        </p>
        <p><strong>Berlinest </strong></p>
        {/* <p>Adresse: [Adresse einfügen]</p> */}
        <p>E-Mail: info@clvansa.de</p>
        {/* <p>Telefon: +49 31 235 486 16</p> */}
      </section>

      <section>
        <h2>3. Erhebung und Verarbeitung von Daten</h2>
        <p>
          Wir erheben personenbezogene Daten, wenn du unsere Webseite besuchst oder ein Nutzerkonto bei uns anlegst. Zu den gesammelten
          Daten gehören:
        </p>
        <ul>
          <li>Persönliche Daten: Name, E-Mail-Adresse, Telefonnummer (falls angegeben)</li>
          <li>Nutzungsdaten: IP-Adresse, Datum und Uhrzeit des Zugriffs, verwendeter Browser, Referrer URL</li>
          <li>Cookies: Wir verwenden Cookies, um deine Präferenzen zu speichern und das Nutzererlebnis zu verbessern. Weitere Informationen
          findest du in unserer Cookie-Richtlinie.</li>
        </ul>
      </section>

      <section>
        <h2>4. Cookies</h2>
        <p>
          Wir verwenden Cookies, um bestimmte Funktionen auf der Webseite zu ermöglichen und das Nutzererlebnis zu verbessern. Cookies
          sind kleine Textdateien, die auf deinem Gerät gespeichert werden. Du kannst die Verwendung von Cookies in den Einstellungen deines
          Browsers jederzeit ablehnen.
        </p>
        <p><strong>Welche Cookies verwenden wir?</strong></p>
        <ul>
          <li>Essentielle Cookies: Diese sind notwendig, damit die Webseite funktioniert (z. B. für die Speicherung deines Login-Status).</li>
          <li>Funktionale Cookies: Diese helfen uns, die Webseite an deine Bedürfnisse anzupassen.</li>
          <li>Analytische Cookies: Wir verwenden diese, um die Nutzung der Webseite zu analysieren und zu verbessern.</li>
        </ul>
        <p>
          Wenn du mehr darüber erfahren möchtest, wie Cookies verwendet werden und wie du sie verwalten kannst, kannst du unsere
          <a href="/cookiericht"> Cookie-Richtlinie</a> einsehen.
        </p>
      </section>

      <section>
        <h2>5. Nutzung der Daten</h2>
        <p>
          Die erhobenen Daten werden zu folgenden Zwecken verwendet:
        </p>
        <ul>
          <li>Bereitstellung unserer Dienste und Funktionen</li>
          <li>Verbesserung unserer Webseite und App</li>
          <li>Personalisierung des Nutzererlebnisses</li>
          <li>Kommunikation mit dir (z. B. zur Beantwortung von Anfragen)</li>
          <li>Erfüllung rechtlicher Verpflichtungen</li>
        </ul>
      </section>

      <section>
        <h2>6. Weitergabe von Daten</h2>
        <p>
          Wir geben deine personenbezogenen Daten nicht an Dritte weiter, es sei denn, dies ist zur Erfüllung eines Vertrags oder aufgrund
          gesetzlicher Vorschriften erforderlich.
        </p>
      </section>

      <section>
        <h2>7. Deine Rechte</h2>
        <p>
          Du hast das Recht, jederzeit Auskunft über deine gespeicherten personenbezogenen Daten zu verlangen und eine Berichtigung oder
          Löschung dieser Daten zu verlangen. Du kannst auch der Verarbeitung deiner personenbezogenen Daten widersprechen.
        </p>
        <p>
          Wenn du von deinen Rechten Gebrauch machen möchtest, kannst du uns jederzeit unter den oben angegebenen Kontaktdaten erreichen.
        </p>
      </section>

      <section>
        <h2>8. Datensicherheit</h2>
        <p>
          Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um deine Daten vor unbefugtem Zugriff, Verlust oder Zerstörung
          zu schützen. Dennoch weisen wir darauf hin, dass die Datenübertragung im Internet immer Sicherheitslücken aufweisen kann, und eine
          vollständige Sicherheit nicht gewährleistet werden kann.
        </p>
      </section>

      <section>
        <h2>9. Änderungen dieser Datenschutzerklärung</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung jederzeit zu ändern. Alle Änderungen werden auf dieser Seite veröffentlicht. Die
          Nutzung unserer Webseite nach Änderungen der Datenschutzerklärung bedeutet, dass du den Änderungen zustimmst.
        </p>
      </section>

      <section>
        <h2>10. Kontakt</h2>
        <p>
          Wenn du Fragen oder Anmerkungen zu dieser Datenschutzerklärung hast, kannst du uns unter den oben angegebenen Kontaktdaten erreichen.
        </p>
      </section>
    </div>
  );
};

const containerStyle = {
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: '#f4f4f4',
};

export default Datenschutz;
