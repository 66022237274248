// src/hooks/useGetLocalStorage.js
import { useState, useEffect } from 'react';

const useGetLocalStorage = (key) => {
  const [storedValue, setStoredValue] = useState(null);

  useEffect(() => {
    // Get item from localStorage
    const item = localStorage.getItem(key);
    // If the item exists in localStorage, parse and set it
    if (item) {
      setStoredValue(JSON.parse(item));
    }
  }, [key]);

  return storedValue;
};

export default useGetLocalStorage;
