import React, { useEffect } from 'react';
import styled from 'styled-components';
import useLocalStorage from '../hooks/useLocalStorge';
import useGetLocalStorage from '../hooks/useGetLocalStorage';
import useStore from '../store/useStore';
const SortWohnungen = () => {

  const { sortKey, sortOrder, setSort, initializeSort } = useStore();
  const { setLocalStorage } = useLocalStorage('sort');

  // const initialSortKey = useGetLocalStorage('sort')?.key || 'sqltime';
  // const initialSortOrder = useGetLocalStorage('sort')?.order || 'desc';

  // const handleSortChange = (e) => {
  //   const [key, order] = e.target.value.split(":");
  //   setSortKey(key);
  //   setSortOrder(order);
  //   setLocalStorage({ key, order })
  // };



  // useEffect(() => {
  //   const item = JSON.parse(localStorage.getItem('sort'));
  //   if (!item?.key || !item?.order || item == undefined) {
  //     setLocalStorage({ key: "sqltime", order: "desc" })
  //   } else {
  //     setSortKey(item.key)
  //     setSortOrder(item.order)
  //   }
  // }, [initialSortKey, initialSortOrder])


  useEffect(() => {
    initializeSort();
  }, [initializeSort]);

  const handleSortChange = (e) => {
    const [key, order] = e.target.value.split(':');
    setSort(key, order); // Update state and localStorage
  };

  return (
    <SortContainer>
      <label htmlFor="sort-options">Sortieren nach:</label>
      <select id="sort-options" onChange={handleSortChange} value={`${sortKey}:${sortOrder}`}>
        <option value="price:asc">Preis : aufsteigend</option>
        <option value="price:desc">Preis : absteigend</option>
        <option value="rooms:asc">Zimmer : aufsteigend</option>
        <option value="rooms:desc">Zimmer : absteigend</option>
        <option value="sqltime:asc">Datum : älteste zuerst</option>
        <option value="sqltime:desc">Datum : neueste zuerst</option>
      </select>
    </SortContainer>
  )
}

export default SortWohnungen


const SortContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  

  select {
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    background-color: transparent;
    color: whitesmoke;
    

  option{
    border-radius: 0;
    color: black;
    border: none;
  }

  &:focus{
    outline: none;
  }
  }

  label{
    font-size: 14px;
  }

  
`;