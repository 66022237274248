import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Apartments from '../components/Apartments';
import Company from '../components/Company';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import useLocalStorage from '../hooks/useLocalStorge';
import useGetLocalStorage from '../hooks/useGetLocalStorage';
import ScrollToTop from '../components/ScrollToTop';
import { Container } from 'lucide-react';
import CookieBanner from '../components/CookieBanner';
import { Helmet } from 'react-helmet';




const Dashboard = () => {
    const [message, setMessage] = useState("")
    const axiosPrivate = useAxiosPrivate();
    const [count, setCount] = useState(0);
    const [refresh, setRefresh] = useState(true)
    // const { setLocalStorage } = useLocalStorage('sort')

    const [errMsg, setErrorMsg] = useState('')
    const [wbs, setWbs] = useState(false);

    const initialSortKey = useGetLocalStorage('sort')?.key || 'sqltime';
    const initialSortOrder = useGetLocalStorage('sort')?.order || 'desc';

    const [sortKey, setSortKey] = useState(initialSortKey); // Default sort by price
    const [sortOrder, setSortOrder] = useState(initialSortOrder); // Default ascending order



    // const checkUser = async () => {
    //     try {
    //         const check = await axiosPrivate.get('/api/user/checkuser', { withCredentials: true });
    //         console.log(check.data)
    //     } catch (error) {

    //     }
    // }

    const notify = async () => {
        try {
            const notifies = await axiosPrivate.get('/api/user/notify')
            console.log(notifies.data)
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <>
            <BigContainer>
                <Helmet>
                    <title>Berlinest - Wohnung Suchen App</title>
                    <meta name="description" content="Berlinest hilft dir, schnell und einfach Wohnungen in Berlin zu finden." />
                    <meta name="keywords" content="Berlin, Wohnungen, Wohnung suchen, Berlinest" />
                    <meta property="og:title" content="Berlinest - Wohnung Suchen App" />
                    <meta property="og:description" content="Finde die besten Wohnungen in Berlin mit Berlinest." />
                </Helmet>
                <Navbar />
                <DashboardContainer>
                    <DashboardWrapper>
                        <Company refresh={refresh} setRefresh={setRefresh} count={count} wbs={wbs} setWbs={setWbs} sortKey={sortKey} sortOrder={sortOrder} setSortKey={setSortKey} setSortOrder={setSortOrder} />
                        <Apartments refresh={refresh} setRefresh={setRefresh} setCount={setCount} wbs={wbs} sortKey={sortKey} sortOrder={sortOrder} />
                    </DashboardWrapper>
                    <ScrollToTop />
                </DashboardContainer>
                <Footer />
                <CookieBanner />
            </BigContainer>
        </>
    )
}

export default Dashboard

const BigContainer = styled.div`
position: relative;
overflow: hidden;

`

const DashboardContainer = styled.div`
  /* padding: 50px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 40px;
  `;

const DashboardWrapper = styled.div`
 padding: 2rem;
  width: 90%;
  max-width: 800px;
  border-radius: 12px;
  min-height: calc(100vh - 4rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); */
  color: #ffffff;
`;