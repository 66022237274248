// src/hooks/useSetLocalStorage.js
const useSetLocalStorage = (key) => {
    const setLocalStorage = (value) => {
      // Save the value to localStorage
      localStorage.setItem(key, JSON.stringify(value));
    };
  
    return setLocalStorage;
  };
  
  export default useSetLocalStorage;
  