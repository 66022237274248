import React, { useState } from 'react';
import axios from 'axios';

const QRCodeGenerator = () => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
    });
    const [qrCode, setQrCode] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const generateQrCode = async () => {
        try {
            const response = await axios.post('http://localhost:3001/generate-qr', formData);
            setQrCode(response.data.qrCode);
        } catch (error) {
            console.error('Error generating QR Code:', error);
        }
    };

    return (
        <div>
            <h2>Contact QR Code Generator</h2>
            <div>
                <input name="firstname" placeholder="First Name" onChange={handleChange} />
                <input name="lastname" placeholder="Last Name" onChange={handleChange} />
                <input name="phone" placeholder="Phone" onChange={handleChange} />
                <input name="email" placeholder="Email" onChange={handleChange} />
                <input name="address" placeholder="Address" onChange={handleChange} />
                <input name="city" placeholder="City" onChange={handleChange} />
                <input name="state" placeholder="State" onChange={handleChange} />
                <input name="postalCode" placeholder="Postal Code" onChange={handleChange} />
                <input name="country" placeholder="Country" onChange={handleChange} />
                <button onClick={generateQrCode}>Generate QR Code</button>
            </div>
            {qrCode && <img src={qrCode} alt="QR Code" />}
        </div>
    );
};

export default QRCodeGenerator;
