import axios from 'axios';
import axiosRetry from 'axios-retry';
const BASE_URL = process.env.REACT_APP_HOST;


export default axios.create({
    baseURL: BASE_URL
});



 const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    timeout:30000,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});


axiosRetry(axiosPrivate, {
    retries: 3, // Retry up to 3 times
    retryDelay: retryCount => retryCount * 1000, // Delay increases with each retry
    retryCondition: error => error?.response?.status >= 500, // Retry on server errors
});


export {axiosPrivate}
