import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
// import axios from '../api/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const PayPalPayment = ({ userId, amount, setType, checkUser }) => {

  const axios = useAxiosPrivate()
  const createOrder = async () => {
    const { data } = await axios.post('/api/payment/create-order', { userId, amount });
    return data.id;
  };

  const onApprove = async (data) => {
    const capture = await axios.post('/api/payment/capture-order', { orderId: data.orderID, userId }).then(async () => {
      setType('loading');
      await checkUser()
      // window.location.reload();
    });
    // alert('Payment successful! Your account has been upgraded to Prime.');
  };

  return (
    <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_CLIENT_API_KEY, currency: "EUR" }} >
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onError={() => {
          // alert('Payment failed.')
          console.log(console);

        }}
        style={{
          layout: "vertical",
          size: "medium",
          shape: "rect",
          color: "blue",
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalPayment;
