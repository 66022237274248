import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleButton = ({ isToggled, handleChange }) => {


  return (
    <ToggleWrapper $isToggled={isToggled} onClick={() => handleChange(!isToggled)}>
      <ToggleCircle $isToggled={isToggled} />
    </ToggleWrapper>
  );
};

export default ToggleButton;

// Styled Components
const ToggleWrapper = styled.div`
  width: 40px;
  height: 21px;
  background: ${({ $isToggled }) => ($isToggled ? '#007bff' : '#ccc')};
  border-radius: 50px;
  /* padding: 5px; */
  display: flex;
  align-items: center;
  justify-content: ${({ $isToggled }) => ($isToggled ? 'flex-end' : 'flex-start')};
  cursor: pointer;
  box-shadow: 0 2px 4px  rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, justify-content 0.3s ease;
`;

const ToggleCircle = styled.div`
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px  rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
`;
