import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxiosPrivate from '../hooks/useAxiosPrivate';



const CompanyDropdown = ({ update, setUpdate, activeCompanies, setActiveCompanies }) => {
    
    const [allUrl, setAllUrl] = useState([]);
    const [updates, setUpdates] = useState();
    const axiosPrivate = useAxiosPrivate()


    const getCompany = async () => {
        try {
            const { data } = await axiosPrivate.get(`api/company/getcompanies`);
            const urlConfig = setAllUrl(data.urlConfigs);
            // setWbs(data.wbs)
            // setRange(data.range)


        } catch (error) {
            console.log('Error:', error);
        }
    };

    useEffect(() => {
        getCompany();
    }, [])


    const handleChange = (companyId, e) => {
        const active = e.target.checked;

        setActiveCompanies((prev) => {
            const itemIndex = prev.findIndex((item) => item.id === companyId);
            if (itemIndex !== -1) {
                const updatedItems = [...prev];
                updatedItems[itemIndex].active = active;
                return updatedItems;
            } else {
                return [...prev, { companyId, active }];
            }
        });
    };

    const handleUpdate = async () => {
        try {
            if (update) {
                await axiosPrivate.post('/api/company/setactive', { companyStatuses: updates }).then(() => {
                    setUpdate(false);
                });
            }

        } catch (error) {
            console.log(error)
        }
    };



    return (
        <div>
            <CustomDropdown>
                <DropdownLabel>Select Company</DropdownLabel>
                <DropdownList>
                    {allUrl.map((all) => (
                        <DropdownItem key={all.id}>
                            <input
                                type="checkbox"
                                name={all.company}
                                defaultChecked={activeCompanies[all.company] || all.active}
                                onChange={(e) => handleChange(all.id, e)}
                                id={all.id}
                            />
                            <Label htmlFor={all.id}>{all.company}</Label>
                        </DropdownItem>
                    ))}
                </DropdownList>
            </CustomDropdown>
        </div>
    );
};

export default CompanyDropdown;

const CustomDropdown = styled.div`
  position: relative;
  width: 200px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
`;

const DropdownLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding-bottom: 8px;

`;

const Label = styled.label`
cursor: pointer;
`

const DropdownList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #ccc;
  background-color: white;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  cursor: pointer;
 
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }

  input {
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    color: #333;
    text-transform: capitalize;
  }
`;
