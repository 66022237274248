import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Navbar = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { logout } = useAuthStore();
  const LogoText = ["Berlin", "Nest"];
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    const device_id = localStorage.getItem('d_id');
    await logout(axiosPrivate, device_id);
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <Logo className="blinding-text">
        <Link to={'/'} style={{ textDecoration: "none" }}>

          <Title >Berli<span >N</span>Est</Title>

        </Link>

      </Logo>
      <Hamburger onClick={toggleMenu} $isOpen={isOpen}>
        <span />
        <span />
        <span />
      </Hamburger>
      <NavLinks $isOpen={isOpen}>
        <NavItem href="/">Home</NavItem>
        <NavItem href="/change-Password">Einstellung</NavItem>
        <NavItem href="/settings">Benachrichtigungen </NavItem>
        <LogoutButton onClick={handleLogout}>Abmelden </LogoutButton>
      </NavLinks>
    </Nav>
  );
};

export default Navbar;

// Styled Components for Navbar
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:  20px ;
  padding-top: 0;
  color: #ecf0f1;
  /* background-color: #34495e; */
`;

const Logo = styled.h1`
  font-size: 1.5em;
  color: #fcfcfc;
  text-transform: uppercase;


`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: #ecf0f1;
    margin-bottom: 4px;
    border-radius: 5px;

    &:nth-child(1) {
      transform: ${({ $isOpen }) => ($isOpen ? 'rotate(45deg) translateY(5px);width:15px' : 'rotate(0)')};
      background: ${({ $isOpen }) => ($isOpen ? 'red' : '#ecf0f1')};
    }

    &:nth-child(2) {
      transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-45deg) translateY(-5px);width:15px' : 'rotate(0)')};
      background: ${({ $isOpen }) => ($isOpen ? 'red' : '#ecf0f1')};
      margin-bottom: ${({ $isOpen }) => ($isOpen ? '0' : '4px')};
    }

    &:nth-child(3) {
      display: ${({ $isOpen }) => ($isOpen ? 'none' : 'block')};
    }
  
  }

 



  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #191b1d;
    width: 100%;
    transform: ${({ $isOpen }) => ($isOpen ? 'translateX(0)' : 'translateX(100%)')};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
`;

const NavItem = styled.a`
  margin: 0 15px;
  font-size: 1rem;
  color: #ecf0f1;
  text-decoration: none;

  &:hover {
    color: #e9b138;
  }

  @media (max-width: 768px) {
    margin: 15px 0;
  }
`;

const LogoutButton = styled.button`
  margin-left: 15px;
  padding: 8px 16px;
  font-size: 1rem;
  color: #ecf0f1;
  background-color: #e74c3c;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }

  @media (max-width: 768px) {
    margin: 15px 0;
  }
`;


const Title = styled.h1`
/* margin: 0;
padding: 0; */
  font-weight: bold;
  font-size: 1.1em;
  letter-spacing: 2px;
  color: #fff;
  /* width: 200px;
  height: 050px; */
  
 
  span{
    color: red;
    position: relative;
    font-weight: bold;
    background-color: #fff;
  }

  `