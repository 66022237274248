import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';

const VerifyAccount = () => {
  const [errMsg, setErrMsg] = useState('');
  const [message, setMessage] = useState('')
  const navigate = useNavigate();
  const location = useLocation();
  const formWrapperRef = useRef(null);
  const from = location.state?.from?.pathname || "/";
  const { verifyAccount } = useContext(AuthContext);


  // Get token from the URL
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');


  useEffect(() => {
    // Animation on page load using GSAP
    gsap.fromTo(formWrapperRef.current, { opacity: 0, y: -50 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
  }, []);

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    handleVerifyAccount()
  }, [])

  useEffect(() => {
    if (!token) {
      navigate(from, { replace: true })
    }
  }, [token])

  // Handle password reset logic
  const handleVerifyAccount = async (e) => {
    try {

      const response = await verifyAccount({ token })

      if (response.statusText == "OK") {
        // navigate('/login');
        setMessage('E-Mail erfolgreich verifiziert!')
      } else {
        
        setErrMsg('Ungültiges oder abgelaufenes Token.');
      }
    } catch (error) {
      console.log(error)
      setErrMsg('Ungültiges oder abgelaufenes Token.')

    }
  };

  return (
    <AuthContainer>
      <FormWrapper ref={formWrapperRef}>
        <Title>Konto verifizieren</Title>
        <p className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
        <SuccessMessage>
          {message && message}
          {message && `
                    Ihr Konto wurde erfolgreich verifiziert!

            Sie können jetzt alle Funktionen auf unserer Plattform nutzen. Viel Spaß beim Entdecken!

        Falls du Fragen hast, steht unser Support-Team jederzeit zur Verfügung.

          Danke, dass du Teil unserer Community bist!
                    `}
        </SuccessMessage>
        <LinkButton onClick={() => navigate('/login')}>Zurück zum Login</LinkButton>
      </FormWrapper>
    </AuthContainer>
  );
};

export default VerifyAccount;

// Styled components
const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #141e30, #243b55);
  animation: gradientBackground 6s ease infinite;
`;

const FormWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: calc(100% - 30px);
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: 0.3s;
  &:focus {
    border-color: #6e8efb;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  margin-top: 1rem;
  background: #6e8efb;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #5a76d4;
    transform: scale(1.05);
  }
`;

const LinkButton = styled.p`
  margin-top: 1rem;
  color: #6e8efb;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;


const SuccessMessage = styled.p`
  color: #333;
  font-size: 1.2rem;
  margin-top: 1rem;
`;

