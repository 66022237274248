import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function CookieBanner() {
    const [showBanner, setShowBanner] = useState(false);

    // Prüfen, ob der Benutzer bereits zugestimmt hat
    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShowBanner(true);
        }
    }, []);

    // Wenn der Benutzer zustimmt, das Banner ausblenden und in localStorage speichern
    const handleAcceptCookies = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
    };

    return (
        showBanner && (
            <div style={bannerStyle}>
                <p>
                    Diese Seite verwendet Cookies, um dein Nutzererlebnis zu verbessern. Weitere Informationen findest du in unserer <a href="/datenschutz">Datenschutzerklärung</a>.
                </p>
                <Button onClick={handleAcceptCookies}>Akzeptieren</Button>
            </div>
        )
    );
}

// Stile für das Banner
const bannerStyle = {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    fontSize: '14px',
    zIndex: 9999
};

const Button = styled.div`
    width: 200px;
    padding: 5px;
    background-color: white;
    color: #252525;
    margin:auto;
    cursor: pointer;
`

export default CookieBanner;
