import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment-timezone';

const Apartment = ({ title, address, rooms, livingSpace, price, url, sqltime, index, company, isNew }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.from(containerRef.current, { opacity: 0, y: 2000, duration: 0.8, ease: "power3.out" });
  }, []);

  const berlinTime = moment.tz(sqltime, "Europe/Berlin");

  // Google Maps URL builder
  const getGoogleMapsLink = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  return (
    <Container ref={containerRef} $isActive={index} data-before-text={index + 1}
      className={index % 2 ? "isActive" : "notActive"}

      $color={index % 2 ? '#bfc2c9' : '#282c34'}
      $background={index % 2 ? '#1b1a1be6' : '#cad4c8'}>
      <Box>
        <TitleBox>
          <Title $isActive={index}>{title}</Title>
        </TitleBox>
        <Wrapper>
          <Address $isActive={index} href={getGoogleMapsLink(address)} target='_blank' rel="noopener noreferrer">{address}</Address>
        </Wrapper>
      </Box>

      <Box>
        <Table>
          <thead>
            <tr>
              <TH $isActive={index}>Preis</TH>
              <TH $isActive={index}>Zimmer</TH>
              <TH $isActive={index}>Fläche</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD $isActive={index}><h3>{price}</h3></TD>
              <TD $isActive={index}><h3>{rooms}</h3></TD>
              <TD $isActive={index}><h3>{livingSpace}</h3></TD>
            </tr>
          </tbody>

        </Table>
      </Box>


      <Box>
        <Time $isActive={index}>
          <Moment fromNow ago>{berlinTime}</Moment>
          <Company $isActive={index}>{company}</Company>
          <small>
            {company !== "deutsche wohnen" || company === "vonovia" ? "warm" : "kalt"}
          </small>

          {isNew &&
            <New $isActive={index}>NEU</New>
          }
        </Time>
        {/* <Wrapper> */}
        <LinkContainer>
          <StyledLink href={url} target='_blank' $isActive={index}>Zum Angebot</StyledLink>
        </LinkContainer>
        {/* </Wrapper> */}

      </Box>
    </Container>
  );
};

export default Apartment;

const Container = styled.div.attrs(props => ({
  style: {
    background: props.$background,
    color: props.$color,
  },
}))`
  position: relative;
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: space-around;
  min-height: 200px;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow:  0px 4px 8px 1px rgba(0, 0, 0, 0.4);
  overflow: hidden;

  &::before{
    content: attr(data-before-text); /* Example static content */
      color: rgba(191, 194, 201, 0.3);
      position: absolute;
     font-size: 6rem;
     left: 30%;
     top: 50%;
     transform: translate(-50%, -50%);
     z-index: 0;
  }
  &.isActive {
    &:before {
      color: rgba(191, 194, 201, 0.3);
    
    }
  }

  &.notActive {
    &:before {
      color: rgba(40, 44, 52, 0.3);
    }
  }

  @media (max-width: 900px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 90%;
    flex-direction:column;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 2;
  width: 33%;
  /* background-color: #242020; */

  @media (max-width: 768px) {
    width: 90%;
    text-align: center;
    align-items: center;
    justify-content: center;
    
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
`;

const Title = styled.h1`
  font-size: 1.2rem;
  text-align: left;
  text-transform: capitalize;
`;

const Address = styled.a`
  color: ${(props) => props.$isActive % 2 ? '#bfc2c9' : '#34495e'};
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: left;
`;

const Company = styled.h5`
  font-weight: 500;
  margin-top: 5px;
  text-transform: capitalize;
  color: ${(props) => props.$isActive % 2 ? '#7f8c8d' : '#2c3e50'};
`;

const Table = styled.table`
  margin: auto;
  text-align: center;
`;

const TH = styled.th`
  padding: 10px;
  font-weight: bold;
  padding-bottom: 0;
  font-size: 1rem;
  color: ${(props) => props.$isActive % 2 ? '#ecf0f1' : '#2c3e50'};
`;

const TD = styled.td`
  padding: 10px;
  font-size: .8rem;
  padding-top: 0;
  color: ${(props) => props.$isActive % 2 ? '#ecf0f1' : '#2c3e50'};
  h3{
    font-weight:600;
  }
`;

const Time = styled.time`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${(props) => props.$isActive % 2 ? '#83868a' : '#7f8c8d'};
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  
`;

const StyledLink = styled.a`
  font-size: 1rem;
  color: #ecf0f1;
  background-color: ${(props) => props.$isActive % 2 ? '#e74c3c' : '#2980b9'};
  padding: 10px 20px;
  border-radius: 5px;
  width: 90px;
  transition: background-color 0.3s ease;
  text-decoration:none;

  &:hover {
    background-color: ${(props) => props.$isActive % 2 ? '#c0392b' : '#1f618d'};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-top: 10px;
  
 
`;

const New = styled.span`
  position: absolute;
  font-size: 1.0rem;
  top: 0;
  right: 5px;
  transform: rotate(90deg);
  color: ${(props) => props.$isActive % 2 ? '#c0392b' : '#1f618d'};
  font-weight: bold;
  user-select: none;
  width: 50px;
  height: 50px;
  overflow: hidden;
  

`
